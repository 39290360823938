import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'RegionId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'RegionNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalSk',
		i18n: 'localsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalCz',
		i18n: 'localcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'RegionNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CitiesCount',
		i18n: 'numberofcities',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
];
