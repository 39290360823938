<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="regions2540"
		tooltipMsg="manualforregion"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'RegionId', ascending: 0 }"
		modelId="RegionId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #RegionNameSk="list">
			<a :href="getUrl(list.row, 'sk')" target="_blank">{{ list.row.RegionNameSk }}</a>
		</template>

		<template #RegionNameCz="list">
			<a :href="getUrl(list.row, 'cz')" target="_blank">{{ list.row.RegionNameCz }}</a>
		</template>

		<template #RegionNamePl="list">
			<a :href="getUrl(list.row, 'pl')" target="_blank">{{ list.row.RegionNamePl }}</a>
		</template>

		<template #RegionNameDe="list">
			<a :href="getUrl(list.row, 'de')" target="_blank">{{ list.row.RegionNameDe }}</a>
		</template>

		<template #RegionNameEn="list">
			<a :href="getUrl(list.row, 'en')" target="_blank">{{ list.row.RegionNameEn }}</a>
		</template>

		<template #RegionNameHu="list">
			<a :href="getUrl(list.row, 'hu')" target="_blank">{{ list.row.RegionNameHu }}</a>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="list.row.CountryId"></country-flag>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import siteUrlBuilder from '@/services/helpers/site-url-builder';

import { tableColumns } from './region.table-data';

export default {
	name: 'RegionTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'RegionListItem',
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(RegionId = 0) {
			this.$router.push({
				name: links.regionDetailName,
				params: { RegionId },
			});
		},

		async prepareColumns(columns) {
			let cols = prepareCountryId.bind(this)(columns);

			return cols;

			function prepareCountryId(columns) {
				const values = this.$store.getters.countryForDropdown;

				return setDropdownOptions(columns, 'CountryId', values);
			}
		},

		getUrl(row, lang) {
			return siteUrlBuilder.buildUrl(row.DomainUrl, lang) + '/' + row.RegionUrlName;
		},
	},
};
</script>