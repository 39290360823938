const builder = {
	buildUrl(baseUrl, newLanguage) {
		var useSSL = false;
		var host = null;
		var port = 0;
		var language = '';
		var country = null;

		parseUrl(baseUrl);

		if (newLanguage != null)
		{
			language = newLanguage;
		}

		return buildUrl();

		/**
		 * Returns string containing site url
		 */
		function buildUrl()
		{
			var scheme = useSSL ? 'https' : 'http';
			var subDomain = language == '' ? 'www' : language.toLowerCase() == country.toLowerCase() ? 'www' : language;
			var hostName = subDomain + '.' + host + '.' + country;

			if (port > 0 && port != '80' && port != '443') {
				return scheme + '://' + hostName + ':' + port;
			} else {
				return scheme + '://' + hostName;
			}
		}

		function parseUrl(baseUrl)
		{
			if (baseUrl.startsWith('http') == false)
			{
				baseUrl = 'http://' + baseUrl;
			}

			var url = new URL(baseUrl);
			useSSL = (url.protocol == 'https:');
			port = url.port;

			var hostFragments = url.hostname.toLowerCase().split('.');
			country = hostFragments[hostFragments.length - 1];

			if (hostFragments.length > 2)
			{
				var possibleLanguage = hostFragments[0];
				switch (possibleLanguage)
				{
					case 'www':
						language = country;
						host = hostFragments.slice(1, hostFragments.length - 1).join('.');
						break;
					case 'sk':
					case 'cz':
					case 'en':
					case 'pl':
					case 'hu':
						language = possibleLanguage;
						host = hostFragments.slice(1, hostFragments.length - 1).join('.');
						break;
					default:
						language = '';
						host = hostFragments.slice(0, hostFragments.length - 1).join('.');
						break;
				}
			}
			else
			{
				language = '';
				host = hostFragments[0];
			}
		}
	}
};

export default builder;
