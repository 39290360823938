var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "regions2540",
          tooltipMsg: "manualforregion",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "RegionId", ascending: 0 },
          modelId: "RegionId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "RegionNameSk",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "sk"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNameSk))]
                  ),
                ]
              },
            },
            {
              key: "RegionNameCz",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "cz"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNameCz))]
                  ),
                ]
              },
            },
            {
              key: "RegionNamePl",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "pl"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNamePl))]
                  ),
                ]
              },
            },
            {
              key: "RegionNameDe",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "de"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNameDe))]
                  ),
                ]
              },
            },
            {
              key: "RegionNameEn",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "en"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNameEn))]
                  ),
                ]
              },
            },
            {
              key: "RegionNameHu",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getUrl(list.row, "hu"),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.RegionNameHu))]
                  ),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: list.row.CountryId },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          4119309732
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }